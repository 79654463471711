
/* Add Customer master form Design */

.AddDiv{

    padding: 30px;
    padding-top: 10px;
    height: fit-content;
    margin-top: 2%;
    /* margin-bottom: 50; */
}

.MasterFormTitle{

    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding: 20px;
    font-size: larger ;
    /* font-weight: bold; */
}

.Addmasterform{

    height: 35px;
}

.imprtgstin{
    margin-left: 2%;
    height: fit-content;
    width: fit-content;
    /* padding-top: 3px ; */
    /* padding-left: 6px; */
    /* padding-right: 6px; */
    /* padding-bottom: 3px; */
    color: black;
    font-size: 13px;    
    font-weight: 400;
    border-radius: 0px;
    border-color: black;
    background-color: white;
}

.imprtgstin:hover{
    background-color: rgba(0, 0, 0, 0.842);
    
}

/* Add user rolesetting form Design */

.userRolepermissiondiv{

    width: 100%;
    justify-content: center;
    /* text-align: center; */
    /* display: flex; */
    /* margin-left: 0%; */
}
.userroleCol{

    width: 100%;
}


/* Add user Profile form Design */
.profileformCol{

    width: 100%;
}

.formFieldSection{

    /* display: flex;
    justify-content: center; */


}