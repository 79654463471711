.footer {
    background-color: #f8f9fa;
    padding: 15px 0;
    text-align: center;
    color: #6c757d;
    border-top: 1px solid #dee2e6;
    margin-top: auto; /* Optional: if you're using flex on the parent */
  }
  
  
  
  .footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }
  
  .footer-links {
    font-size: 11px;
    /* margin-bottom: 10px; */
  }
  
  .footer-link {
    color: #007bff;
    text-decoration: none;
    margin: 0 10px;
  }
  
  .footer-link:hover {
    text-decoration: underline;
  }
  
  .footer-divider {
    margin: 0 5px;
    color: #6c757d;
  }
  
  .footer-company {
    font-size: 14px;
    color: #6c757d;
    /* padding-bottom: -10px; */
  }
  