.tablediv{
    padding-right: 5px;
    padding-left: 5px;
    overflow-y: auto;
  }



#myInvoiceTable thead th {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color:black;
    background-color: #f8f9fa; /* Ensure a solid background color for the header */
    z-index: 2;
    padding: 15px;
    /* padding-bottom: 10px; */
    padding-top: 22px;
    position: sticky;
    top: 0;
    /* max-height: 5px; */
    /* height: 5px; */
    /* margin-top: 100px; */
    /* width: 160px; */
    /* padding: 10px; */
    /* padding-bottom: 0px; */
    /* Add any other desired font properties */
  }


  .form-check-input{

    margin-top: 4px;
    margin-left: 15px;
    height: 15px;
    width: 15px;
    /* width: 100%; */
  }

  .checkInput1{

    margin-top: 8px;
  }

  .form-check-input:hover{

    cursor: pointer;
  }


  #myInvoiceTable tbody td{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px; 
    /* width: 150px; */
    /* font-weight: 600; */
    color:black;
    /* height: 10px;/ */
    /* padding: 2px; */
    /* padding-left: 0%; */
    /* margin-top: 5px; */
    padding-bottom: 0px;
    /* padding-top: 0px; */
    /* margin-bottom: 0px; */
    

    /* Add any other desired font properties */
  }
#myInvoiceTable tbody tr{

  padding-bottom: 0px;

}
  #MyinvoiceDiv{
    /* margin-top: 40px; */
    /* padding-top: 10px; */
  }

  .MyinvoicePaginationDiv{
    margin-left: 5px; 
    /* margin-bottom: px; */
    /* height: 10px; */
  }

  #MyinvoiceTableDiv{
    height: 400px;
    margin-top: -15px ;
    padding-top: 0px;
    border-radius: 0px;
    /* width:fit-content */
    /* width:101%; */

  }

  #alertDIV{
    /* height: 30px; */
    /* display: flex;
    justify-content: center; */
    /* width: 1000px;s */


  }

  .CancelIrnDIV{
    /* align-items: center; */
    display:flex;
    width: 100%;
    justify-content: center;
  }

  #irnCancelField{

    width: 200px;
  }

  #irnCancelreasonField{

    width: 200px;
    /* padding-right: 30px; */
  }
  #irnCancelremarkField{

    width: 200px;
  }

  #noDataDiv{

/* 
    width: 100%;
    height: 100%;
    margin-left:500% ; */
  }


  #noDataText{

    /* margin-left: 550%; */
    /* width: 100%; */
    /* margin-top: 90%; */
    text-align: center;
  padding: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #6c757d; 
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  #numberOfItemstext{
    margin-right: 3px;
    margin-top: 8px;
    font-size: small;
    color: rgba(0, 0, 0, 0.5);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .MyinvoicePaginationDiv{

    display: flex;
    justify-content: right;
    margin-right: 0%;
    padding-top: 10px;
    padding-right: 10px;
  }

  .bottomline2{

    height: 2px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.034);
    /* margin-top: 10px; */

  }


  .selected-row {
    background-color: #bee8fc; /* Color for selected row */
  }
  
  .active-row {
    background-color: rgb(212, 237, 218);; /* Light green for Active status */
  }
  
  .error-row {
    background-color: rgb(255, 228, 225); /* Light red/pink for error */
  }
  
  /* If both selected and error, make sure selected-row doesn't override error-row */
  .selected-row.error-row {
    background-color: rgb(255, 200, 200); /* Slightly darker pink for selected error rows */
  }
  
  