.datepickdiv{
    /* height: 35px; */
    display: flex;
    /* width: 210px; */
    /* padding: 0%; */
    /* margin: 0%   ; */
    margin-left: 10px;
}

.strdate.form-label{
    font-size: small;
    /* width: 100px; */
    margin-top: 5px;
    /* margin-left: 5px; */
    margin-right: 5%;
    color: rgba(0, 0, 0, 0.534);
    /* margin: 0%; */
    /* padding: 0%; */
    
}

.rightcon2{

    /* border: rgb(252, 248, 16) 2px solid; */
    margin-right: 50px;
    /* justify-content: left; */
    display: flex;
    width: 100%;
    /* height: 50px; */
    justify-content: center;
}



#applydatebtn{
    height: fit-content;
    width: fit-content;
    padding-top: 3px ;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 3px;
    font-size: 14px;    
    font-weight: 400;
    border-radius: 5%;
    color: black;
    border-color: rgba(0, 0, 0, 0.192);
    /* border-radius: 0%; */
    background-color: white;
    margin-left: 7px;
    margin-top: 3px;
    
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#applydatebtn:hover{
    background-color: rgba(0, 0, 0, 0.151);
}


.form-control2{
    width: 117px;
    height: fit-content;
    border-radius: 5%;
    padding-top: 4px ;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 4px;    
    font-size: 13px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

