@media screen and (min-width: 600px){
    .headersignup{
    
        margin-top: 3%;
        text-align: center;
        font-size: 28px;
        color: black;
        /* margin-bottom: 2%; */
    }
    .mainSignup{
      display: flex;
      flex-direction: column;
      min-height: 100vh;

      }


    .SignUpFormDiv{

      display: flex;
      justify-content: center;
      align-items: center;
      /* margin-left: 100px; */
    }

    .PersonalDetailDiv{
      display: flex;
    }

    .SectionHeading{
      font-size: medium;
    }

}
    
    @media screen and (max-width: 600px){
        .headersignup{
        
            margin-top: 27%;
            text-align: center;
            font-size: 28px;
            color: black;
        }
        .mainSignup{
    
            margin-top: 7%;
          }

        .googlesignup{
    
            width: 30%;
            height: auto;
            margin-left: 35%;
            margin-top: 10%;
            margin-bottom: 2%;
            display: block;
            
            
          }
    
    }
    
    
 

    .form-signin {
      max-width: 100%;
      padding: 15px;
      padding-top: 0px;
    }

    input.SignupFormControl.form-control{

      font-size: small;
      padding-top: 20px;
      width: 500px;
      /* height: 40px; */
      margin-top: 0%;
      /* margin-right: 500px; */
    }

  
    .SignupFloatingInput{
      font-size: 13px;
      padding-bottom: 0px
    }
    
    .SignUpFormFloating{
      margin-right: 50px;
      margin-top: 25px;
      width: 500px;
      /* height: 40px; */
    }
 
    .form-signin input[type="emailsignup"] {
      
      /* border-bottom-right-radius: 0; */
      /* border-bottom-left-radius: 0; */
      height: 45px;
    }
    
    .form-signin input[type="passwordsignup"] {
      height: 45px;
    }
    
    .form-signin button[type="submitsignup"] {
      margin-bottom: 10px;
      margin-left: 0%;
    
    }
    
    
    .signuplab{
      margin-left: 30%;
    }
    
    
    
    .signinlink{
    
      text-align: center;
    }

    .signinlink:hover{

      color: blue;
    }

    .PersonalDetailSection{

      /* margin-left: 100px; */
      margin-top: 15px;
      width: fit-content;

    }

    #SignupBtnClick{

      margin-top: 20px;
      /* width: 7%; */
      font-size: medium;
    }
    

    .submitBtnDiv{

      width: 100%;
      text-align: center;
    }

    .StateDiv{

      padding-top: 10px;
    }
    
    #floatingInput{
      /* height: 40px; */
    }
    
    .SignUpChildDiv{


    }