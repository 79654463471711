#menucheck{
    color: red;
    background-color: white;
}



.tablediv{
  padding-right: 5px;
  padding-left: 5px;
  /* height: 450px; Set the desired height for the table body */
  overflow-y: auto;
}



#masterTable thead th {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 14px;
  font-weight: 500;
  color:black;
  /* padding: 10px; */
  /* Add any other desired font properties */
  
}


.form-check-input{

  margin-top: 6px;
  margin-left: 15px;
  height: 17px;
  width: 17px;
  /* width: 100%; */
}



.form-check-input:hover{

  cursor: pointer;
}


#masterTable tbody td{
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px; 
  /* font-weight: 600; */
  color:black;
  /* padding-top: 10px; */
  /* padding: 10px; */
  /* padding-left: 0%; */

  /* Add any other desired font properties */
}

.tbody{
  height: 10px;
}

.gstinWidth{
  width: 50px;
}

.trdNameWidth{
  width: 50px;
}

.legalWidth{
  width: 50px;
}
.emailWidth{
  width: 300px;
}

.phWidth{
  width: 50px;
}

.checkWidth{
  width: 50px;
}


#tableBodyDiv{

  margin-top: -15px;
  height: 351px;
}

.selected-row {
  background-color: #e6e6e6; /* Set the background color for selected rows */
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
}

  /* .pagination{
    margin-top: 5px;
  } */


  