.dpicon{

    height: 30px;
    width: auto;
    object-fit: cover;
    border-radius: 50%;
    margin-top: 0%;
    margin-right: 0%;
    margin-left: 45%;
    /* border: black solid 1px; */
}

.dpicon:hover {
    cursor: pointer;
}

.icon-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    /* border: none; */
    background-color: #4CAF50;
    color: white;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 7%;
    border: #4CAF50;
  }
  
  .icon-button i {
    margin-right: 5px;
    
  }




  
  .element {
    margin: 10 10px;

    }
  
    .bottomline{

        height: 2px;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.034);
        margin-top: 10px;

    }

    #customNavcon{

        margin-right: 0%;
 
        display: flex;
        justify-content: right;
        margin-top: 1%;
  
    }

    .customNav{

        margin-right: 2%;
  
    }

    #selecterSellerText{

        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 14px;
        margin-right: 400px;
        /* margin-top: 5px; */
    }

    

   
  