#nav1{
    width: 100%;
}


@media screen and (max-width: 600px) {

    .navloginbtn{

        font-weight: bold;
        display: none;
    }
    
    .navsignupbtn{
    
        font-weight: bold;
        display: none;
    }

}


@media screen and (min-width: 600px) {

    .navloginbtn{

        font-weight: bold;

    }
    
    .navsignupbtn{
    
        font-weight: bold;
        margin-left: 5px;

        margin-right: 9px;
    }


}