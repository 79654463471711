.FormTitle{


 margin-top: 10px;
 font-weight: bold;
 font-size: medium;
   }

.TitleUl{

    height: 2px;
    background-color: rgba(0, 0, 0, 0.548);
    width: 20px;
}

#formMainTitle{

    display:flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    margin-left: 10px;
}