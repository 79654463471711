.Itemtablediv{
    padding-right: 5px;
    padding-left: 5px;
  }

  .table thead th {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color:rgba(0, 0, 0, 0.658);
    /* color: black; */
    padding: 15px;
    /* Add any other desired font properties */
  }


  .table thead{

    background-color: rgba(0, 0, 0, 0.027);
    /* border-radius: 50px; */
    
    /* Add any other desired font properties */
  }
  



  .table tbody td{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 12px; 
    /* font-weight: 600; */
    color:black;
    padding: 5px;
    padding-left: 15px;
    /* padding-left: 0%; */

    /* Add any other desired font properties */
  }

  .itemTable tbody td{
    padding-left: 0px;
  }
 

  .itemnm{

    width: 100%;

  }
.itemhsn{

    width: 100px;
    margin-top: 16px;
}

.itemqty{

    width: 70px;
    margin-top: 16px;
}

.itemprice{

    width: 70px;
    margin-top: 16px;
}

.itemdiscount{

    /* width: 70px; */
}

.form-check-input{

    width: 100%;
}

.submitinvbtndiv{
  margin-top: 16%;
  align-items: center;
  display:flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 0%;
}

#saveinvbtn{

  height: fit-content;
  width: 200px;
  margin-top: 5px;
  padding-top: 3px ;
  /* padding-left: 6px; */
  /* padding-right: 6px; */
  padding-bottom: 3px;
  font-size: 14px;    
  font-weight: 500;
  border-radius: 5%;
  border-color: black;
  background-color: rgb(0,7,61);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#cancelinvbtn{

  height: fit-content;
  width: 200px;
  margin-top: 5px;
  margin-left: 5px;
  padding-top: 3px ;
  /* padding-left: 6px; */
  /* padding-right: 6px; */
  padding-bottom: 3px;
  font-size: 14px;    
  font-weight: 500;
  color: black;
  border-radius: 5px;
  border-color: black;
  background-color: transparent ;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#saveinvbtn:hover{

  background-color: rgba(0, 0, 0, 0.863);
}

#cancelinvbtn:hover{

  background-color: rgba(0, 0, 0, 0.034);
}

.dltitem{

  margin-left: 15px;
  color: rgba(0, 0, 0, 0.205);
  margin-top: 10px;
}

.dltitem:hover{

 cursor: pointer;
}


.otherchrgTableDiv{

  width: fit-content;
  margin-top: 15px;
  margin-right: 150px;
  /* display: none; */
}

.belowitemDiv{
  width: 100%;
  display: flex;
  justify-content: center;

}


.table-responsive{

  border-radius: 10px;
}

.totaldiv{

  /* border: red 2px solid; */
  border-radius: 10px;
  padding: 20px;
  width: fit-content;
  height: fit-content;
  margin-left: 10px;
  background-color: rgba(0, 255, 255, 0.041);
}


.totaltextsDiv{

  display: flex;
  justify-content: left;

  padding: 0%;

}

.totaltextsfont1{
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding: 0%;
    /* margin-left: 4px; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.totaltextsfont2{
  font-size: 13px;
  padding: 0%;
  margin-left: 200px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.644);
  /* font-weight: 500; */
    /* margin-left: 4px; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.cessfield{

  width: 55px;
  height: 25px;
  font-size: small;
  padding:2px;
  border-radius: 0%;
}

.ItemTitle{

  /* margin-top: 16%; */
  align-items: center;
  display:flex;
  /* justify-content: center; */
  width: 100%;
  /* margin-bottom: 0%; */

}


.impGstin{
  /* align-items: center; */
  display:flex;
  width: 100%;
  justify-content: center;
}

#impGstinText{
  margin-top: 3px;
}

.AddItembtn{
  height: fit-content;
  width: 200px;
  margin-top: 5px;
  margin-left: 15px;
  padding-top: 3px ;
  /* padding-left: 6px; */
  /* padding-right: 6px; */
  padding-bottom: 3px;
  font-size: 11px;    
  font-weight: 500;

  color: black;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0.24);
  background-color: transparent ;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.AddItembtn:hover{

  background-color: rgba(0, 0, 0, 0.171);
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide spinners in number inputs for Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}


.disField{

  width: 90px;
  margin-top: 16px;
}

.itemunit{

  width: 90px;
  margin-top: 16px;
}

.itemTaxRate{

  margin-top: 16px;
  width: 70px;

}

.isServcCheck{
   
  margin-top: 14px;
  width: 100%;

}


.isServiceCheck{

  /* width: 100%; */
  /* margin-left: 5px; */
}


.itemdesc1{

  padding-top: 0px;
}


.ItemNameSection{
  background-color: white;
  width: 100%;
}

#impGSTINField{

  width: 200px;
}
