.react-tabs__tab {
    font-size: 16px;
    color: #555;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    padding: 10px;
  }
  
  .react-tabs__tab--selected {
    color: #222;
    border-bottom: 2px solid #222;
    font-weight: bold;
  }
  
  .react-tabs__tab-panel {
    font-size: 20px;
    color: #222;
    padding: 20px;
  }

  .react-tabs__tab-list{

    border-bottom: none;
    justify-content: center;
    display: flex;
  }

  .settingnav{

    /* margin-top: 10px; */
    margin: 10px;
  }
  
  .nav-tabs{
    /* margin-left: 700px; */
    /* background-color: rgba(0, 0, 0, 0.616); */
    width: 100%;
    margin: 0;
  }

  #menu1{
    /* background-color: rgb(25, 51, 197); */
    /* margin: 0px; */
    /* width: 100%; */
  }

  #home{

    /* background-color: rgb(16, 165, 16); */
  }

  #menu2{
    /* background-color: rgb(175, 45, 45); */
  }

  .sellertitle{

    color: black;
  }

  .sellertitle:hover{

    color: black;
  }

  

  #adsellerbtn{

    width: fit-content;
    height: fit-content;
    font-size:14px;
    padding: 6px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    background-color: royalblue;
  }

  .btnsDiv{

    display: flex;
    justify-content: left;
    height: fit-content;
    align-items: center;
  }

