* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;

}
.sidebarMain {

  height: 630px;
  overflow: auto;
  width: 100%;

}

.routes {
  flex: 1; /* Takes up available space, pushing the footer down */
  overflow-y: auto; /* Allow scrolling if content exceeds the viewport height */
}



.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
  text-align: center;
}

.sidebar {
  background: rgb(0, 7, 61);
  color: white;
  height: 100vh;
  width: 20%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* min-height: 100vh; */
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  width: 30px;
  margin-bottom: 5%;
  cursor: pointer;
  /* margin-right: 0; */
  /* margin-left: 10%; */
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  margin-top: 1px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
  display: flex;
  color: white;
  gap: 10px;
  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  text-decoration:none;
}
.link:hover {
  border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.active {
  border-right: 4px solid white;
  /* background: rgb(45, 51, 89); */
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
  font-weight:600;
  margin-top: 3px;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}

.horizontal{
position: absolute;
width: 80%;

right: 0%;

}

.vertical{
position:static;

  
}

.ddseller{

  /* margin-left: 50px; */
  /* width: 100px; */
}

#sellerdrop{

  border-color: white;
  color: white;
}

.form-select{
  color: black;
  background-color: rgba(0, 0, 0, 0.068);
  border-color: grey;
  width: 95%;
  height: 5px; 
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* font-weight: 600; */
  font-size: 55px;
  /* padding-top: 1; */
  /* margin: 5px; */
  /* margin-right: 40px; */
  /* padding-left: 0; */
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  cursor: pointer;
}

.form-select:focus {
  /* background-color: #d1d8de; */
  border-color: #ffffff;

  outline: none; /* Remove the default blue outline */
}

.form-select option {
  background-color: black;
  color: white;
  cursor: pointer;
}


.cursor-pointer{
  cursor: pointer;
}


.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 1); /* Adjust the alpha value (0.5) to control the darkness */
  z-index: 1040; /* Make sure the z-index is higher than the modal */
}


.modal-footer{
  justify-content: center;
}