
*{
    box-sizing: border-box;
}

@media screen and (max-width: 600px) {

  h3{

    font-size: 38px;
    font-weight: 700;

    margin-left: 10px;
    margin-right: 10px;
    font-family: "Jost", sans-serif;
    color: #fff;
    width: 320px;
    padding-top: 60px;
    
    
  }

  .callToButton{

    border: 2px solid #0d6efd;
    background-color:  #0d6efd;
    color: #fff;
    text-align: center;
    margin-left: 31%;
    margin-top: 3%;
    margin-bottom: 3%;
    width: 160px;
    height: 40px;
    border-radius: 30px;
}

  img{
    display: none;
  }
}
.contain{

    background-color: #212529;
    height: 500px;
    width: 100%;
    display: grid;
  grid-template-columns: auto auto auto;


}

.col1{

    width: 100%;
    margin: 7%;

}

.col2{
    margin: 50px;
    
}



@media screen and (min-width: 600px) {
h3{
    display: block;
  margin-left: 90px;
    font-size: 48px;
    font-family: "Jost", sans-serif;
    font-weight: 700;
    line-height: 56px;
    width: 500px;
    color: #fff;
    line-height: 1.4;
    padding-top: 60px;

}

.callToButton{

    border: 2px solid #0d6efd;
    background-color:  #0d6efd;
    color: #fff;
    text-align: center;
    
    margin-left: 42%;
    margin-top: 3%;
    margin-bottom: 3%;
    width: 180px;
    height: 60px;
    border-radius: 30px;
}

}

img{

    height: auto;
    
    width: 400px;
    margin-top: 20px;
    margin-left: 100px;
    vertical-align: middle;

 } 

#getstartbtn{

margin-left: 100px;
margin-top: 20px;

}

.cards{


    width: 80%;
    margin-top: 30px;
    margin-left: 10%;
    text-align: center;
    padding: 20px;


}

.card-img-top{

  
    height: auto;
    width: 15px;
    margin-left: 176px;
    margin-top: 15;

}

.card-body{
    margin-top: -35px;
}

#lastcard{


    height: 136px;
}

.accord{

   
    color: white;

    margin-top: 30px;
    margin-bottom: 30px;
}
.accordion accordion-flush{
    padding-right: 10%;

}

.accordion-item{
    width: 80%;
    margin-left: 11%;
    margin-right: 15%;
    
}

.accordion-header{
    background-color: blue;

}

#heading2{

    text-align: center;
    color: black;
    font-family: "Jost", sans-serif;
    font-weight: bold;
    margin-top: 40px;


}

.accordion-body{

    text-align: center;
    font-family: "Jost", sans-serif;
}

#accordionbut{

    font-weight: bold;

    margin-left: 0px;
}


footer{
    background-color: black;
    height: 65px;
    padding-top: 20px;
}

.foot{

    text-align: center;
    color: white;
}


.hl1{

   
    height: 2px;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 10px;   
    background-color: #21252918;
    


}

.subhead1{

    text-align: center;
    font-weight: 40;
    font-size: 17px;
}

.callToButton:hover{

    background-color: #0d6dfd3a;
}
