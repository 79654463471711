.subscription-status {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 30px;
    background-color: #f8f9fa;
    border-radius: 8px;
    max-width: 450px;
    margin: 0 auto;
    color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .subscription-status h2 {
    font-size: 1.8em;
    margin-bottom: 20px;
    color: #0073e6;
  }
  
  .status {
    font-size: 1.2em;
    padding: 10px;
  }
  
  .status.active {
    color: #28a745;
  }
  
  .status.inactive {
    color: #dc3545;
  }
  
  .status p {
    margin: 8px 0;
  }
  
  .status span,
  .status strong {
    font-weight: bold;
  }
  
  .contact-info {
    margin-top: 20px;
    font-size: 1em;
    color: #555;
  }
  
  .contact-info p {
    margin: 5px 0;
  }
  
  .contact-info a {
    color: #0073e6;
    text-decoration: none;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  