/* Styles for the control (the main input field) */
.react-select__control {
    background-color: #f9f9f9;
    border: 5px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    box-shadow: none;
    height: 150px;
  }

  .css-hlgwow{
    margin-top:-4px ;
  }

  .css-1jqq78o-placeholder{
    color: black;
  }
  
  .css-1wy0on6{

    margin-top: -5px;
  }

  .css-tj5bde-Svg{
    /* margin-top: -4px; */
  }
  
  /* Change border color on hover */
  .react-select__control:hover {
    border-color: #007bff;
  }
  
  /* Styles for the dropdown menu */
  .react-select__menu {
    z-index: 999; /* Ensure the dropdown appears above other elements */
    
  }
  
  /* Styles for options */
  .react-select__option {
    padding: 10px;
    
  }
  
  /* Change background and text color for focused options */
  .react-select__option--is-focused {
    background-color: #007bff;
    color: white;
    
  }
  
  /* Styles for the selected value in the control */
  .react-select__single-value {
    color: #333;
    
  }
  