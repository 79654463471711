.customerdtlDiv{

    padding: 15px;
    padding-top: 20px;
    padding-bottom: 20px;
    /* margin-left: 45px; */
    width: 100%;
    margin-top: 0%;
    background-color: rgba(65, 105, 225, 0.034);
    border-radius: 15px;
}

#nicPasswordEye{

    margin-left: 5px;
}

.nicPasswordDiv{

    display: flex;
    justify-content: center;
}

.col-md-2{

    margin-right: 30px;
    margin-top: 5px;
}

.form-control{

    border-radius: 5px;
    height: 27px;
    margin-top: 3px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.form-check-input{

}

.form-label{

    font-size: 14px;
    font-weight: 500;
    /* margin-left: 4px; */
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#igstonintraswitch{

    margin-top: 30px;
}


.form-check-label{

    font-size: 14px;
    font-weight: 500;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.form-select{

    border-radius: 5px;
    height: 27px;
    font-size: 11px;
}

.option{

    font-size: 12px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    /* font-weight: 400; */

}

.taxinvoicediv{

    padding-left: 15px;
    padding-right: 15px;
}


.my-4{
    color: rgba(0, 0, 0, 0.219);
    
}

/* Master form START below*/

