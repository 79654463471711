/* Example CSS to style the dropdown */
#state {
    background-color: #f5f5f5;
    color: #333;
    border: 1px solid #ccc;
    font-size: 12px;
    /* padding: 5px; */
    border-radius: 5px;
    font-family: system-ui;
    width: 100%;
    height: 27px
  }
  