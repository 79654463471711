#actioncon{

    display: flex;
    justify-content: left;
    /* margin-top: 1%; */
    height: fit-content;

padding-left: 10px;
padding-right: 10px;
    /* border: black 2px solid; */

}

#genbtn{

    height: fit-content;
    width: fit-content;
    padding-top: 3px ;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 3px;
    font-size: 14px;    
    font-weight: 400;
    border-radius: 5%;
    border-color: black;
    background-color: rgb(0,7,61);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#genbtn:hover{
    background-color: rgb(45, 74, 161)
}

#editbtn{
    height: fit-content;
    width: fit-content;
    padding-top: 3px ;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 3px;
    font-size: 14px;    
    font-weight: 400;
    border-radius: 5%;
    color: black;
    border-color: rgba(0, 0, 0, 0.192);
    /* border-radius: 0%; */
    background-color: white;
    margin-left: 9px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
#editbtn:hover{
    background-color: grey  ;
}

#dltbtn{
    height: fit-content;
    width: fit-content;
    padding-top: 3px ;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 3px;
    font-size: 14px;    
    font-weight: 400;
    border-radius: 5%;
    color: black;
    border-color: rgba(0, 0, 0, 0.192);
    /* border-radius: 0%; */
    background-color: white;
    margin-left: 9px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
#dltbtn:hover{
    background-color: grey  ;
}


#Morebtn{

    height: fit-content;
    width: fit-content;
    padding-top: 3px ;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 3px;
    font-size: 14px;    
    font-weight: 400;
    border-radius: 5%;
    /* color: black; */
    /* border-color: rgba(0, 0, 0, 0.555); */
    /* border-radius: 0%; */
    margin-left: 9px;
    background-color: rgb(0,7,61);
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
#printbtn{

    height: fit-content;
    width: fit-content;
    padding-top: 3px ;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 3px;
    font-size: 14px;    
    font-weight: 400;
    border-radius: 5%;
    color: royalblue;
    border-color: royalblue;
    /* border-color: rgba(0, 0, 0, 0.555); */
    /* border-radius: 0%; */
    margin-left: 9px;
    background-color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.rightcon{

    /* border: rgb(236, 10, 10) 2px solid; */
    /* margin-left: 50px; */
    justify-content: right;      
    display: flex;
    width: 100%;
    height: fit-content;
}

.srch{

    /* margin-left: 10px; */
    margin-right: 8px;
}
