#text1Title{
    color: black;
    font-size:small;
    margin-left: 0%;
    padding-top: 5%;
}

#mb-3modify{
    height: 480px;
}
.mb-3{

}

#generatebtn{

        width: 100%;
        background-color: rgb(112, 10, 245);
        border-color: rgb(112, 10, 245);
}

#generatebtn:hover{
    background-color: rgba(112, 10, 245, 0.61);
}

.leftsec{
    width: 40%;
    margin-left: 10px;
    border: 1px solid rgba(112, 10, 245, 0.233);
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.09);

}

#form-control2{

    border-color: rgba(112, 10, 245, 0.233);
    border: 1px solid rgba(112, 10, 245, 0.233);
    
}

.generateimg{

    height: 150px;
    width: 150px;
    margin-top: 0%;
    margin-left: 38%;
    margin-bottom: 40px;
}

.fullsec{
    display: flex;
    align-items: center;
}


#generatehead{
    text-align: center;
    margin: 0%;
    padding: 0%;
    color: (0, 0, 0, 0.829);
    font-weight: bold;
}


.rightsec{

    width: 100%;
    margin-top: 0%;
}