.alertDiv{
    justify-content: center;
    display: flex;
    align-items: center;
}

.alert{
    width: 50%;
    font-size: small;
    /* height: 45px; */
    padding: 8px;
}

.btn-close{
    margin-top: -9px;
    margin-right: -7px;
    /* margin-left: 10px; */
    /* padding: 100px; */
}