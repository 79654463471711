@media screen and (min-width: 600px){
.header{

    margin-top: 80px;
    text-align: center;
    font-size: 28px;

    color: black;
}
.mainLogin{

width: 50%;
margin-left: 25%;
}
.googlesignin{

  width: 12%;
  height: auto;
  margin-left: 44%;
  margin-top: 5%;
  margin-bottom: 2%;
  
  
}
}

@media screen and (max-width: 600px){
    .header{
    
        margin-top: 27%;
        text-align: center;
        font-size: 28px;
        color: black;
    }
    .mainLogin{

      margin-top: 7%;
    }

    .googlesignin{

      width: 30%; 
      height: auto;
      margin-left: 35%;
      margin-top: 10%;
      margin-bottom: 2%;
      display: block;
      
      
    }
  }


body {

}

.form-signin {
  max-width: 330px;
  padding: 15px;
}


.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-signin button[type="submit"] {
  margin-bottom: 10px;
  margin-left: 0%;

}


.lab{
  margin-left: 30%;
}



.signlink{

  text-align: center;
/* width: fit-content; */
}

.signlink:hover{
  color:blue;
}



.form-floating{
margin-top: 10px;

}

#LoginBtnClick{
  margin-top: 20px;
      /* width: 7%; */
      font-size: medium;
}


.loginform{

  width: 330px;
}

.loginDiv{

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}